<template>
    <div>
        <el-dialog
            :title="$t('devtable.tjqjcc')"
            :visible.sync="centerDialogVisible"
            width="980px"
            append-to-body
            center>
            <div class="schdolgfoot">
                <div class="yuang"> {{$t('devtable.yg')}}</div>
                <div class="staff">
                    <div class="staffleft">
                        <div class="stafflefttop">
                            <span style="width:80px;line-height: 32px;">{{$t('devtable.bm')}} </span>
                            <SelectSingle @handleCheckChange="handleCheckChange"></SelectSingle>
                            <!-- <el-select v-model="value" :placeholder="$t('devtable.qxz')" size="small" style="flex:4">
                                <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select> -->
                        </div>
                        <el-row  style="display: flex;padding-left:55px">
                            <el-input size="small" v-model="input_name" :placeholder="$t('devtable.xm')" style="flex:3;margin-right:20px"></el-input>
                            <el-row  style="flex:2;line-height: 32px;"> <el-button type="primary" @click="searchWithName" size="small">{{$t('devtable.cx')}}</el-button></el-row>
                        </el-row>
                    </div>
                    <div class="staffright">
                        <p>{{$t('devtable.yxygH')}}</p>
                    </div>
                </div>
                <div style="padding:25px">
                    <Tablelave :tableData="tableData_b" ref="transTab"></Tablelave>
                </div>
            </div>
            <div class="details">
                <el-col :span="3" style="line-height: 32px;">
                    {{$t('devtable.qjlb')}}
                </el-col>
                <el-col :span="18">
                    <el-select v-model="type" :placeholder="$t('devtable.qxz')" size="small">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>
            </div>
            <div class="details">
                <el-col :span="3" style="line-height: 32px;">
                    {{$t('devtable.ksrq')}}
                </el-col>
                <el-col :span="5">
                    <el-date-picker
                    size="small"
                    v-model="time_start"
                    type="datetime"
                    :placeholder="$t('devtable.xzrq')">
                    </el-date-picker>
                  
                </el-col>
                
            </div>
            <div class="details">
                <el-col :span="3" style="line-height: 32px;">
                    {{$t('devtable.jsrq')}}
                </el-col>
                <el-col :span="5">
                    <el-date-picker
                    size="small"
                    v-model="time_end"
                    type="datetime"
                    :placeholder="$t('devtable.xzrq')">
                    </el-date-picker>
                </el-col>
                
            </div>
            <div class="details">
                <el-col :span="3" style="line-height: 32px;">
                    {{$t('devtable.mx')}}
                </el-col>
                <el-col :span="18">
                    <el-input
                    size="small"
                    placeholder=""
                    v-model="remark"
                    clearable>
                    </el-input>
                </el-col>
            </div>
            
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handserqingj">{{$t('tablename.qd')}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Tablelave from './tablelave'
import SelectSingle from '@/components/selectree/selectchoice'
export default {
    components:{
        Tablelave,
        SelectSingle
    },
    data(){
        return{
            user_id:'',
            type:'',
            time_end:'',
            time_start:'',
            remark:'',
            value1:'',
            tableData_b:[],
            form: {
                name: '',
                date1:''
            },
            input_name:'',
            input:"",
            centerDialogVisible: false,
            datas:[] ,
            vluass:[1,4],
            options: [{
                value: '0',
                label:this.$t('devtable.sjj')
                }, {
                value: '1',
                label: this.$t('devtable.bjj')
                }, {
                value: '2',
                label: this.$t('devtable.cch')
                }, {
                value: '3',
                label: this.$t('devtable.cjj')
                }, {
                    value: '4',
                    label: this.$t('devtable.njj')
                },{
                value: '5',
                label: this.$t('devtable.hsjq')
            },{
                value: '6',
                label: this.$t('devtable.qtt')
            }],
            value: '',
            UserInfo:''
        }
    },
    mounted(){
      this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
    },
    methods:{
        // 添加请假
        handserqingj(){
            let userId=[]
            this.$refs.transTab.tableData_b.forEach(el=>{
                console.log(el)
                userId.push(el.id)
            })
            if(userId.length == 0){
                this.$message({
                    type: 'warning',
                    message: this.$t('devtable.qxzyg')
                })
                return false;
            }
            let parmast={
                method:'att.askleave.add',
                agent_id:this.UserInfo.agent_id,
                user_id:userId.toString(),
                type:this.type,
                time_start:Math.round(new Date(this.time_start) / 1000),
                time_end:Math.round(new Date(this.time_end) / 1000),
                remark:this.remark
            }
            this.$serve(parmast).then(res=>{
                console.log(res)
                if(res.data.code == 0){
                    this.$message({
                        type: 'success',
                        message:this.$t('devtable.tjcg')
                    })
                    this.centerDialogVisible = false;
                    this.$parent.getqingjrylist()
                }else{
                    this.$message({
                        type: 'error',
                        message: this.$t('devtable.tjsb')
                    })                    
                }
            })
        },
        handleCheckChange(sel_dept){
            console.log(`898`,sel_dept);
            this.dept_id = sel_dept;
            this.$refs.transTab.getEmployeeListWithDept(sel_dept,this.input_name);
        },
        // 
        searchWithName(){
            if(this.input_name.length == 0){
                this.$message({
                    type: 'warning',
                    message:this.$t('devtable.qsrygxm')
                })
            }else if(this.dept_id.length == 0){
                this.$message({
                    type: 'warning',
                    message: this.$t('devtable.qxxzbm')
                })
            }else{
                this.$refs.transTab.getEmployeeListWithDept(this.dept_id,this.input_name);
            }
        },
        
    }
}
</script>
<style lang="less" scoped>
.schdolgfoot{
    border: 1px solid #ccc;
    position: relative;
    height: 435px;
    margin-top: 20px;
    .yuang{
        width: 50px;
        height: 20px;
        text-align: center;
        background-color: #fff;
        position: absolute;
        top:-10px;
        left: 20px;
    }
    .staff{
        display: flex;
        padding: 20px;
        .staffleft{
            flex: 1;
            .stafflefttop{
                display: flex;
                margin-bottom: 20px;
                margin-left: 10px;
            }
        }
        .staffright{
            flex: 1;
            p{
                text-align: center;
            }
        }
    }
}
.details{
    padding: 25px;
}
.line{
    text-align: center;
    line-height: 30px;
}
</style>