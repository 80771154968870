<template>
    <div class="schedling">
        <div style="display: flex;">
            <Headbuttom></Headbuttom>
            <Tabheader @handRegister="handRegister" @handFromshow="handFromshow" :titlename="titlename"></Tabheader>
        </div>
        
         <div class="attheadfrom" v-show="flag">
            <el-form :inline="true" size="small" :model="formInline" class="demo-form-inline">
                <!-- <el-form-item label="部门">
                    <el-select v-model="formInline.region" placeholder="活动区域">
                    <el-option label="区域一" value="shanghai"></el-option>
                    <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item :label="$t('devtable.xm')">
                    <el-input v-model="formInline.name" :placeholder="$t('devtable.xm')"></el-input>
                </el-form-item>
                <el-form-item :label="$t('devtable.ghHH')">
                    <el-input v-model="formInline.number" :placeholder="$t('devtable.ghHH')"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">{{$t('devtable.cx')}}</el-button>
                </el-form-item>
            </el-form>
        </div>
        <Tables :tableLabel="tableLabel" @handlpages="handlpages" @handleSizeChange="handleSizeChange" @handlerow="handlerow" :tableData="tableData" :flags="flags" :configs="configs" :operation="operation"></Tables>
        <Leavmandiolg ref="leavmandiolg"></Leavmandiolg>
        <Cleadr ref="cleadr" @clearhand="clearhand" :data="clearldata"></Cleadr>
        <Leavemandeidt ref="leavmandeidt2"></Leavemandeidt>
    </div>
</template>
<script>
import Tables from '@/components/table'
import Tabheader from './components/tabheader'
import Leavmandiolg from './components/leavemandiolg'
import Leavemandeidt from './components/leavemandeidt2'
import { formatDate } from '@/utils/date'
import Cleadr from './components/cleadr'
export default {
    components:{
        Tables,
        Tabheader,
        Leavmandiolg,
        Leavemandeidt,
        Cleadr
    },
    data(){
        return{
            clearldata:{},
            value1:'',
            flag:false,
            formInline: {
                name: '',
                number:''
            },
            titlename:{
                name:this.$t('tablename.zj'),
                placeholdername:'请点击查询'
            },
            
            tableData: [],
            flags: {
                show: false, // 多选是否显示
                ishow: false // 序号是否显示
            },
            // 分页数据
            configs: {
                page_index:1,
                pagesize: 10,
                total: 200,
                loading: false // 加载状态
            },
           
            UserInfo:''
        }
    },
    computed:{
         operation:function(){
             return{
                width:'200px',
                data:[
                {
                    name: this.$t('devtable.xg'),
                    type: 'primary'
                  },
                  {
                    name:  this.$t('tablename.sc'),
                    type: 'danger'
                  },
                ]
            }
         } ,
        tableLabel:function(){
            
        return[
                {
                    prop: 'user_name',
                    label: this.$t('devtable.xm'),
                    align: 'center'
                },
                {
                    prop: 'user_number',
                    label: this.$t('devtable.ghHH'),
                    align: 'center'
                },
                {
                    prop: 'time_start',
                    label: this.$t('devtable.ksrqH'),
                    align: 'center'
                },
                {
                    prop: 'time_end',
                    label: this.$t('devtable.jsrqH'),
                    align: 'center'
                },
                {
                    prop: 'remark',
                    label: this.$t('devtable.ms'),
                    align: 'center'
                },
                {
                    prop: 'type',
                    label: this.$t('devtable.qjlxH'),
                    align: 'center'
                },
            ]
        } 
    },
    watch:{
        "formInline.name":function(oval,neval){
            if(oval != neval){
                this.getqingjrylist()
            }
        },
        "formInline.number":function(oval,neval){
            if(oval != neval){
                this.getqingjrylist()
            }
        }
    },
    mounted(){
        this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
        this.getqingjrylist()
    },
    methods:{
        getqingjrylist(v,name,number){
            let parmst={
                method:'att.askleaves.get',
                agent_id:this.UserInfo.agent_id,
                dept_id:'',
                name:name?name:"",
                number:number?number:'',
                page_index:v?v:this.configs.page_index,
                page_size:this.configs.pagesize,
            }
            this.$serve(parmst).then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.configs.total = res.data.data.recordCount
                    let data=res.data.data.records
                    data.forEach(element => {
                        if(element.time_start){
                           element.time_start= formatDate(new Date(element.time_start*1000), 'yyyy-MM-dd')
                        }
                        if(element.time_start){
                           element.time_end= formatDate(new Date(element.time_end*1000), 'yyyy-MM-dd')
                        }
                        if(element.type==0){
                            element.type=this.$t('devtable.sjj')
                        }else if(element.type==1){
                            element.type=this.$t('devtable.bjj')
                        }else if(element.type==2){
                            element.type=this.$t('devtable.cch')
                        }else if(element.type==3){
                            element.type=this.$t('devtable.cjj')
                        }else if(element.type==4){
                            element.type=this.$t('devtable.njj')
                        }else if(element.type==5){
                            element.type=this.$t('devtable.hsjq')
                        }else {
                            element.type=this.$t('devtable.qt')
                        }
                    });
                    this.tableData = data
                }
            })
        },
        // 修改删除
        handlerow(v,name){
            if(name=="删除" || name=="Delete"){
                 this.clearldata={
                    id:v.id,
                    method:'att.askleave.delete'
                }
                this.$refs.cleadr.dialogVisible = true
            }else{
                this.$refs.leavmandeidt2.getryuzil(v)
                this.$refs.leavmandeidt2.centerDialogVisible=true
            }
        },
        // 删除回调列表
        clearhand(){
            this.getqingjrylist()
        },
        onSubmit(){
            this.getqingjrylist('',this.formInline.name,this.formInline.number)
        },
        handRegister(){
            this.$refs.leavmandiolg.centerDialogVisible=true
        },  
        // 分页
        handlpages(v){
            this.configs.page_index = v
            this.getqingjrylist()
        },
        handleSizeChange(v){
            this.configs.page_index = 1
            this.configs.pagesize=v
             this.getqingjrylist()
        },
        handFromshow(){
            this.flag = !this.flag
        }
    }
}
</script>
<style lang="less" scoped>
.schedling{
    padding: 20px;
    background-color: #fff;
    .attheadfrom{
        padding: 20px;
        margin-top: 10px;
    }
}
</style>